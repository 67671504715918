.mobile-view {
  display: none !important;
}

.desktop-view {
  display: flex !important;
}

// body {
//   overflow: auto !important;
// }

.faq-each-items-style {
  padding: 0px;
}

.perks-mobile-items-margin-style {
  margin-left: 0px;
}

.education-video-text-container-mobile-style {
  padding-left: 0px;
  margin-left: 10px;
}

.sticky-thc-option-container {
  // font-family: helvetica, arial, sans-serif;
  // padding: 4em 2em 50em 2em;
  position: relative;
  z-index: 1;
}

.sticky-thc-option-list {
  // position: sticky !important;
  position: fixed !important;
  top: 0;
  right: 0px;
  z-index: 5 !important;
  background: white;
}

.timer-mobile-margin-top {
  margin-top: 40px;
}

.sticky-thc-option-list-timer-style-top {
  // position: sticky !important;
  margin-top: 0px !important;
  position: fixed !important;
  top: 0px;
  // left: 24px;
  z-index: 100;
  // z-index: 5 !important;

  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;

  background: white;
  border: 0px;
  border-top: 1px solid #6161cf;
  box-shadow: -2px 6px 8px 0px rgb(143 143 155 / 75%);
}

.sticky-thc-option-list-timer-style-bottom {
  // position: sticky !important;
  margin-top: 0px !important;
  position: fixed !important;
  bottom: 0px;
  // left: 24px;
  z-index: 100;
  // z-index: 5 !important;

  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;

  background: white;
  border: 0px;
  border-top: 1px solid #6161cf;
  box-shadow: -2px 6px 8px 0px rgb(143 143 155 / 75%);
}

.sticky-thc-option-list-timer-style {
  // position: sticky !important;
  position: fixed !important;
  top: 0px;
  margin-top: 0px !important;
  // left: 24px;
  z-index: 100;
  // z-index: 5 !important;

  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;

  background: white;
  border: 0px;
  border-top: 1px solid #6161cf;
  box-shadow: -2px 6px 8px 0px rgb(143 143 155 / 75%);
}

.sticky-timer-style-invisible-style {
  display: none !important;
}

.sticky-header-still-part-style-here {
  margin-bottom: 0px;
  background: white;
}

.timer-chapter-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: solid 1px #4747B0;
  background-color: #F3F1EE;
  position: absolute;
  top: 0;
}

.timer-chapter-circle-active {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: solid 1px #F3F1EE;
  background-color: #4747B0;
  position: absolute;
  top: 0;
}

.education-cur-items-scroll-btn-style {
  // background: transparent !important;
  border: 0px;
  box-shadow: none !important;
}

// body{
//   >div{
//     height: 100%;
//     overflow: auto;
//   }
// }

.account-delete-note-button-text-style {
  text-transform: capitalize;
  font-size: 16px;
  font-family: Helvetica;
}

.global-font-family {
  font-family: montserrat;
}

.global-flex-center-just-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.global-flex-center-just-space-between {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  margin-left: 0px;
}

.contact-form-maring-top-style {
  margin-top: 20px;
  // display: flex;
  // justify-content: space-between;
  // align-items: flex-end;
  // flex-direction: column;
  // height: 40vh;
}

.favorite-card-group-title-text-style {
  font-size: 36px;
  line-height: 42px;
  color: #4747b0;
  text-align: left;
}

.account-header-part-padding-style {
  padding-left: 105px;
  padding-right: 105px;
}

.contact-mbtn-margin-top {
  margin-top: 15px !important;
}

.overflow-scroll-horzontial-container {
  position: relative;
  width: 100%;
  margin-bottom: 26px !important;
}

.overflow-scroll-horzontial {
  // overflow-y: auto !important;
  max-width: 100%;
  overflow-x: auto;
  display: flex !important;
  position: absolute !important;
  top: 0px;
  left: 0px;
}

.login-question-text-container-style-new {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.new-education-desc-text-style {
  font-family: 'Montserrat';
  margin-top: 24px;
  font-weight: 500;
}

.new-education-search-bar-style {
  max-width: 280px !important;
  margin-top: 26px;
}

.new-education-search-field-item-height-style {
  height: 34px !important;
}

.new-header-container-padding-style {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.new-footer-container-padding-style {
  padding-top: 40px !important;
  padding-bottom: 20px !important;
}

// .new-education-react-player-style {
//   width: 100vw;
//   height: 225px;
// }

.new-mobile-menu-item-text-style {
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.new-home-notes-section-container-style {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-image: linear-gradient(to right, #5dc7c7, #d9db22, #DADB22 100%);
  min-height: 64px;
  padding-left: 195px;
}

.new-home-notes-sub-container-style {
  display: flex;
}

.new-home-notes-text-margin-style {
  margin-left: 12px;
}

.new-home-notes-go-page-text-style {
  margin-left: 8px;
  margin-right: 22px;
  font-weight: bold;
  cursor: pointer;
}

// .new-home-notes-book-img-style {
//   width: 35px;
//   height: 35px;
// }

.new-home-general-favorite-button-style {
  border: 2px solid #1E4BB9 !important;
  margin-bottom: 55px;
  color: black !important;
  font-weight: 500 !important;
}

.home-favorite-workout-controller-left-style {
  position: absolute !important;
  left: 290px;
  z-index: 10;
}

.home-favorite-workout-controller-right-style {
  position: absolute !important;
  right: 290px;
  z-index: 10;
}

@media (max-width: 1700px) {
  .home-favorite-workout-controller-left-style {
    left: 170px;
  }

  .home-favorite-workout-controller-right-style {
    right: 170px;
  }
}

@media (max-width: 1600px) {
  .home-favorite-workout-controller-left-style {
    left: 135px;
  }

  .home-favorite-workout-controller-right-style {
    right: 135px;
  }
}

@media (max-width: 1500px) {
  .home-favorite-workout-controller-left-style {
    left: 70px;
  }

  .home-favorite-workout-controller-right-style {
    right: 70px;
  }
}

@media (max-width: 1400px) {
  .home-favorite-workout-controller-left-style {
    left: 35px;
  }

  .home-favorite-workout-controller-right-style {
    right: 35px;
  }
}

@media (max-width: 1300px) {
  .home-favorite-workout-controller-left-style {
    left: 5px;
  }

  .home-favorite-workout-controller-right-style {
    right: 5px;
  }
}


.new-education-header-text-bg-style {
  background: #edd3ff;
  margin-top: -35px;
  width: 235px;
  height: 35px;
}

.new-favorites-header-text-bg-style {
  background: #edd3ff;
  margin-top: -35px;
  width: 225px;
  height: 35px;
}

.new-recovery-header-text-bg-style {
  background: #edd3ff;
  margin-top: -35px;
  width: 218px;
  height: 35px;
}

.new-home-past-challlenge-header-text-bg-style {
  background: #edd3ff;
  margin-top: -35px;
  width: 385px;
  height: 35px;
}

@media (max-width: 991px) {
  .new-education-header-text-bg-style {
    margin-top: -21px;
    width: 147px;
    height: 20px;
  }

  .new-favorites-header-text-bg-style {
    margin-top: -21px;
    width: 141px;
    height: 20px;
  }

  .new-recovery-header-text-bg-style {
    margin-top: -21px;
    width: 136px;
    height: 20px;
  }

  .new-home-past-challlenge-header-text-bg-style {
    background: #edd3ff;
    margin-top: -21px;
    width: 242px;
    height: 20px;
  }
}

@media (max-width: 768px) {
  .new-education-header-text-bg-style {
    margin-top: -21px;
    width: 147px;
    height: 22px;
  }

  .new-education-header-text-bg-style {
    margin-top: -21px;
    width: 147px;
    height: 22px;
  }

  .new-recovery-header-text-bg-style {
    margin-top: -21px;
    width: 136px;
    height: 22px;
  }

  // .home-workout-card-controller-btn {
  //   display: none;
  // }

  .new-home-notes-section-container-style {
    padding-left: 12px;
  }

  .new-home-general-favorite-button-style {
    margin-bottom: 35px;
  }

  .new-home-notes-sub-container-style {
    display: block;
    margin-left: 16px;
    margin-right: 16px;
  }

  .new-home-notes-section-container-style {
    align-items: flex-start;
    padding-top: 22px;
    min-height: 106px;
  }

  .new-education-search-bar-style {
    max-width: 100% !important;
    padding-left: 12px;
  }

  .new-education-react-player-style {
    padding-left: 12px;
    // padding-right: 12px;
  }

  .new-header-container-padding-style {
    padding-top: 20px !important;
    padding-bottom: 40px !important;
  }

  .sticky-thc-option-list-timer-style {
    right: 0px;
  }

  .overflow-scroll-horzontial {
    max-width: 100%;
  }

  .workout-skeletontext-margin-style {
    margin-left: 15px;
    margin-right: 15px;
  }

  .account-exercise-card-image-margin-top {
    margin-top: 50px !important;
  }

  .account-header-part-padding-style {
    padding-left: 5px;
    padding-right: 5px;
  }

  .favorite-card-group-title-text-style {
    font-size: 24px;
    line-height: 32px;
    color: #4747b0;
    text-align: left;
  }

  .contact-form-maring-top-style {
    margin-top: 10px;
  }

  .mobile-view {
    display: flex;
  }

  .desktop-view {
    display: none;
  }

  .faq-each-items-style {
    padding: 10px;
  }

  .perks-mobile-items-margin-style {
    margin-left: 16px;
  }

  .education-video-text-container-mobile-style {
    margin-left: 0px;
    padding-left: 0px !important;
  }

  .sticky-thc-option-list-timer-style-mobile-top {
    // position: sticky !important;
    margin-top: 0px !important;
    position: fixed !important;
    top: 0px;
    // left: 24px;
    z-index: 100;
    // z-index: 5 !important;
  
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
  
    background: white;
    border: 0px;
    border-top: 1px solid #6161cf;
    box-shadow: -2px 6px 8px 0px rgb(143 143 155 / 75%);
  }

  .sticky-thc-option-list-timer-style-mobile-bottom {
    // position: sticky !important;
    margin-top: 0px !important;
    position: fixed !important;
    bottom: 0px;
    // left: 24px;
    z-index: 100;
    // z-index: 5 !important;
  
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
  
    background: white;
    border: 0px;
    border-top: 1px solid #6161cf;
    box-shadow: -2px 6px 8px 0px rgb(143 143 155 / 75%);
  }

  .timer-mobile-margin-top {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top:20px;
  }

  // .sticker-timer-margin-top-new-style{
  //   position: fixed !important;
  //   top: 0px;
  //   left: 24px;
  //   z-index: 100;
  // }
  .education-dropdown-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 55px;
  }

  .drop-down-option-mobile-style {
    margin-bottom: 3px;
    margin-top: 3px;
  }

  .mobile-account-note-inputer-style {
    margin-left: 9px;
  }

  .open-mobile-dropdown-top-container-style {
    background: #979797;
    position: absolute !important;
    top: 0px;
    z-index: 9999;
    width: 100%;
    height: 100vh;
    opacity: 0.9; // 0.6;
  }

  .open-modal-scroll-small-top-size {
    background: white;
    opacity: 1 !important;
    width: 100%;
    position: fixed !important;
    z-index: 99999;
    height: 100vh;
    top: 97px;
  }

  .open-mobile-dropdown-top-container-style-new-education-part {
    background: #979797;
    position: absolute !important;
    top: 0px;
    z-index: 9999;
    width: 100%;
    height: 100%; // 100vh;
    opacity: 0.9; // 0.6;
  }

  .open-modal-scroll-small-top-size-new-education-part {
    background: white;
    opacity: 1 !important;
    width: 100%;
    position: fixed !important;
    z-index: 99999;
    // height: 100vh;
    top: 0px;
  }

  .open-mobile-dropdown-btn-bottom-style-new {
    border-bottom: 2px solid #C08DE2;
    height: 47px;
  }

  .open-dropdown-item-child-container-style {
    margin-top: -8px;
  }

  .open-mobile-dropdown-real-items-conatiner-style {
    background: white;
    opacity: 1 !important;
    width: 100%;
    position: fixed !important;
    z-index: 99999;
  }

  .mobile-bottom-dropdown-style-scroll {
    // overflow-x: scroll;
    overflow-y: scroll;
    max-height: 100vh; // 200px
  }

  .iteration-set-items-overflow-style-mobile {
    max-width: 100vw;
    overflow-x: scroll;
  }
}



// .active-link-style {
//   font-weight: bold !important;
//   border-bottom: 3px solid #edd3ff !important;
// }

.new-header-menu-inactive-container-style {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.new-header-menu-active-container-style {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: -5px;
}

.new-header-menu-active-link-style {
  background: #edd3ff;
  width: 100%;
  margin-top: -13px;
  z-index: -5;
  height: 10px;
}

.new-header-active-link-text-style {
  font-weight: bold;
  font-size: 16px;
}

.new-header-account-icon-box-container {
  margin-top: -6px;
  margin-left: 12px;
}

@media (max-width: 1410px) {
  .new-home-welcom-banner-width-style {
    width: 100% !important;
  }
}

.new-desktop-menu-group-sub-hover-container-style {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.new-desktop-menu-group-sub-hover-container-style:hover .new-desktop-menu-group-sub-hover-style {
  background: #C08DE2;
}

.new-desktop-menu-group-sub-hover-style {
  width: 15px;
  height: 12px;
  background: white;
  margin-right: 2px;
}

.desktop-header-menu-group-sub-a-link-under-line {
  text-decoration: none !important;
}

.new-mobile-menu-group-sub-active-style {
  width: 15px;
  height: 12px;
  background: #EDD3FF;
  margin-right: 2px;
}

.new-mobile-menu-group-sub-inactive-style {
  width: 15px;
  height: 12px;
  background: #e7f8f5;
  margin-right: 2px;
}
.slick-left-arrow{
    display: flex;
    flex-direction: row;
    text-align: center;
    left: -50;
    top: 'calc(50% - 60px)';
    width: 150px;
    height: 30px;
    margin-right: 2;
    position: absolute;
    align-items: center;
}
@media screen and (max-width: 540px) {
  .slick-prev {    
    position: absolute;
    top: 12% !important;
    left: 0 !important;
    z-index: 2;
    &::before {
      content: "";
    }   
  }
  .slick-next {  
    position: absolute;
    top: 12% !important;    
    right: 25px !important;
    &::before{
      content: "";
    }    
  }
}
